$blue: #0099cc;
$gold: #999933;

#mainScreen {
	background: $blue;
	width: 100%;
	margin: 0 auto;
	a {
		text-decoration: none;
		#mainTitle {
			padding: 1rem;
			text-align: center;
			color: #fff;
		}
	}
	#navbar {
		display: flex;
		justify-content: space-around;
		align-items: center;
		background-color: #999933;
		width: 100%;
		position: sticky;
		list-style: none;
		z-index: 10;
		padding: 0.5rem 0;
		.nav-item {
			a {
				color: #fff;
				text-decoration: none;
				padding: 0.5rem 0.75rem;
				&:hover {
					background: #00000011;
				}
				&:active {
					background: #00000022;
				}
				&.active {
					background: #00000033;
				}
			}
		}
	}
}
