#preloader {
	z-index: 1000;
	position: fixed;
	background: #0099cc;
	color: #fff;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	#balls > div {
		position: absolute;
		width: 5rem;
		height: 5rem;
		background: #999933;
		border-radius: 50%;
		transform: translate(150%, 50%);
	}
	#balls {
		#ball1 {
			animation: juggle ease 1.2s infinite;
		}
		#ball2 {
			animation: juggle ease 1.2s infinite 0.4s;
		}
		#ball3 {
			animation: juggle ease 1.2s infinite 0.8s;
		}
    }
    &.preload-finished {
        transition: 1.2s ease;
        opacity: 0;
        pointer-events: none;
    }
}

// Animations
@keyframes juggle {
	0% {
	}
	33% {
		transform: translate(-150%, 50%);
	}
	66% {
		transform: translate(0, -200%);
	}
	100% {
		transform: translate(150%, 50%);
	}
}
