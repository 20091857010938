#projects {
	display: flex;
	flex-wrap: wrap;
	#vid {
		flex: 3 1 600px;
		order: 1;
		position: relative;
		padding-bottom: 25px;
		padding-top: 56.25%;
		height: 0;
		width: 100%;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@media (min-width: 1024px) {
				padding: 0 5rem;
			}
		}
	}
	#vidInfo {
		height: 100%;
		flex: 1 1 300px;
		order: 2;
		background: #b93a3a;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		@media (min-width: 1024px) {
			height: auto;
		}
		a {
			order: 1;
			@media (min-width: 1024px) {
				order: 3;
			}
			button {
				transition: opacity 0.3s;
				cursor: pointer;
				border: none;
				background: #0198cd;
				color: #fff;
				padding: 0.5rem 1rem;
				margin: 1rem;
				border-radius: 5px;
				opacity: 1;
				&:hover {
					opacity: 0.9;
				}
				&:active {
					opacity: 0.8;
				}
				@media (min-width: 1024px) {
					order: 3;
					margin: 0;
				}
			}
		}
		p {
			order: 2;
			text-align: center;
			color: #fff;
			font-size: 10pt;
			padding: 0 1rem 1.5rem 1rem;
			@media (min-width: 1024px) {
				order: 2;
			}
		}
		img#vidInfoImage {
			order: 3;
			width: 250px;
			height: 250px;
			margin: 0 1.5rem 1.5rem 1.5rem;
			border-radius: 50%;
			border: 10px solid white;
			@media (min-width: 1024px) {
				order: 1;
			}
		}
	}
}
