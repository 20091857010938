* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html, body, #root, .App {
	background: url('../assets/bg.png');
	background-size: 100px;
	min-height: 100vh;
}

.App {
	position: relative;
	#notFooter {
		min-height: 100%;
		// Padding matches footer height
		padding-bottom: 17rem;
		@media (min-width: 768px) {
			padding-bottom: 11rem;
		}
	}
}

.cal {
	color: #fdd400;
}