#contactContainer {
	padding: 1rem;
	#contact {
		background-color: #0198cd;
		padding: 1.5rem;
		border-radius: 1rem;
		@media (min-width: 768px) {
			width: 80%;
			margin: 3rem auto;
		}
		@media (min-width: 1024px) {
			width: 30%;
			margin: 5rem auto;
		}
		.colTit {
			margin: 0;
			padding: 0;
		}
		.form-input,
		#formMessage {
			display: block;
			width: 90%;
			margin: 1.5em auto;
			border: none;
			padding: 0.7rem;
			border-radius: 10px;
			&.submit {
				padding: 0.7rem;
				margin: 1.5rem auto;
				color: white;
				background-color: #fdd400;
				&:hover {
					background-color: #fdd300e7;
				}
				&:active {
					background-color: #fdd300e3;
				}
			}
			@media (min-width: 1024px) {
				width: 80%;
			}
		}

		.input-error {
			box-shadow: 0 0 5px rgb(185, 58, 58);
		}

		.form-error {
			color: rgb(185, 58, 58);
		}
		#form-message {
			color: rgb(185, 58, 58);
		}

		.form-success {
			color: white;
		}
	}

	.colTit {
		padding: 25px 0;
		color: #fff;
		text-align: center;
		span {
			color: #ffcc00;
		}
	}
}
