footer#footer {
	background-color: #999933;
	position: absolute;
	bottom: 0;
    width: 100%;
    height: 17rem;
    @media (min-width: 768px) {
        height: 11rem;
    }
	#mainFooterStuff {
		display: flex;
		flex-direction: column;
		@media (min-width: 768px) {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}
	#footerRight {
		@media (min-width: 768px) {
			order: 1;
			border: none;
			width: 50%;
		}
		color: #fff;
		font-size: 10pt;
		border-bottom: 1px #fff solid;
		#contDets {
			text-align: center;
			list-style-type: none;
			margin: 0 0 1rem 0;
			li > a {
				color: #fff;
			}
		}
	}
	#footerLeft {
		@media (min-width: 768px) {
			order: 0;
			border: none;
			width: 50%;
		}
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 10pt;
	}
	.footerTitle {
		margin: 0.5rem 0 0.3rem 0;
		padding: 0;
		font-size: 13pt;
		color: #fff;
	}
}

.toolLink {
	list-style-type: none;
	color: #fff;
	text-decoration-line: none;
}

.toolLink > li {
	opacity: 0.6;
	transition: ease 0.3ms;
}

.toolLink:hover > li {
	opacity: 0.9;
}

#iconText {
	font-size: 7pt;
	text-align: center;
	clear: both;
	padding: 0 0 0.3rem 0;
	color: #ccc;
}

#iconText > a {
	color: #ccc;
}

#copyText {
	font-size: 7pt;
	text-align: center;
	clear: both;
	padding-top: 1rem;
	color: #ccc;
}
