#home {
	display: flex;
	flex-direction: column;
	#homeJumbo {
        background: #000000dd;
        display: flex;
        justify-content: center;
		#homeImage {
			width: 100%;
			height: auto;
			@media (min-width: 1024px) {
				width: 50%;
				margin: 3rem auto;
				border-radius: 1rem;
			}
		}
	}
	#homeComponents {
		display: flex;
		flex-direction: column;
		@media (min-width: 768px) {
			flex-direction: row;
		}
		#social {
			padding: 3rem;
            text-align: center;
            flex: 1;
			@media (min-width: 768px) {
				display: flex;
				justify-content: center;
				align-items: center;
				order: 2;
			}
			#socContent {
				background-color: #b93a3a;
				border-radius: 1rem;
				color: #fff;
                padding: 1rem;
                @media (min-width: 768px) {
                    padding: 3rem;
                }
                @media (min-width: 1024px) {
                    padding: 4rem;
                }
				.colTit {
					margin: 0;
					padding: 0;
				}
				.socIcon {
					width: 4rem;
					padding: 0 1rem;
					opacity: 1;
					transition: 1s ease;
					&:hover {
						opacity: 0.9;
						width: 5rem;
					}
					&:active {
						opacity: 0.8;
						width: 5rem;
					}
				}
			}
        }
        #availability {
            background: #0099cc;
            flex: 1;
            align-items: center;
            padding: 1.5rem 0;
            #calendar-wrapper {
                max-width: 450px;
                margin: 0 auto;
                .colTit {
                    color: #fff;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                }
                #calKey {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    margin: 0.25rem 0 0 0;
                    small {
                        display: flex;
                        align-items: center;
                        #free {
                            background: #999933;
                            width: 0.5rem;
                            height: 0.5rem;
                        }
                        #busy {
                            background: #b93a3a;
                            width: 0.5rem;
                            height: 0.5rem;
                        }
                        #unconfirmed {
                            background: #d6770a;
                            width: 0.5rem;
                            height: 0.5rem;
                        }
                        p {
                            margin-left: 0.25rem;
                            color: #fff;
                        }
                    }
                }
                #calendar {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0;
                    .react-calendar {
                        margin: 0 0 1.5rem 0;
                        border: none;
                        border: 1px solid #fff;
                        border-radius: 1px;
                        width: 100%;
                        z-index: 1;
                        .react-calendar__navigation {
                            background: #0099cc;
                            margin: 0;
                            border: none;
                            button {
                                color: #fff;
                                &:hover {
                                    background-color: #0088b6;
                                }
                                &:focus {
                                    background-color: #0099cc;
                                }
                                &:active {
                                    background-color: #0088b6;
                                }
                                &:disabled {
                                    background-color: #0088b6;
                                }
                            }
                        }
                        .react-calendar__month-view {
                            background: #999933;
                            color: #fff;
                            .react-calendar__month-view__weekdays
                                > .react-calendar__month-view__weekdays__weekday
                                > abbr {
                                text-decoration: none;
                            }
                            .react-calendar__month-view__days {
                                background: #fff;
                                .react-calendar__tile {
                                    background: #999933;
                                    color: #ddd;
                                    &:hover {
                                        background: #88882b;
                                        color: #fff;
                                    }
                                    &.react-calendar__tile--active {
                                        background-color: #88882b;
                                        color: #fff;
                                    }
                                    &.react-calendar__month-view__days__day--neighboringMonth {
                                        background: #88882b;
                                        &:hover {
                                            color: #ddd;
                                        }
                                    }
                                    &.busy {
                                        background-color: #b93a3a;
                                        &:hover {
                                            background-color: #a52a2a;
                                        }
                                        &.react-calendar__tile--active {
                                            background-color: #a52a2a;
                                        }
                                    }
                                    &.poss {
                                        background-color: #d6770a;
                                        &:hover {
                                            background-color: #c75411;
                                        }
                                        &.react-calendar__tile--active {
                                            background-color: #c75411;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    @media (min-width: 600px) {
                        padding: 1rem;
                    }
                }
            }
        }
	}
}
